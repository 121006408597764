interface AuthConfig {
  AUDIENCE: string;
  ISSUERDOMAIN: string;
  SCOPES: string;
  EMPLOYEESVCURL: string;
  EMPLOYEEPICTUREURL: string;
}

type AtlasConfig = {
  ENVIRONMENT: string;
  BACKEND_URL: string;
  BACKEND_URL_V2: string;
  BEKK_HOST: string;
  SLACK_ERROR_HOOK_URL: string;
};

type ENV = AuthConfig & AtlasConfig;

declare global {
  interface Window {
    SERVER_DATA: ENV;
  }
}

const env: ENV =
  process.env.NODE_ENV === 'development'
    ? {
        ENVIRONMENT: process.env.NODE_ENV,
        BACKEND_URL: process.env.REACT_APP_BACKEND ?? 'http://localhost:8080',
        BACKEND_URL_V2: process.env.REACT_APP_BACKEND_URL_V2 ?? 'http://localhost:8081',
        BEKK_HOST: 'https://api.bekk.dev',
        AUDIENCE: 'QHQy75S7tmnhDdBGYSnszzlhMPul0fAE',
        ISSUERDOMAIN: 'bekk-dev.eu.auth0.com',
        SCOPES: 'openid name groups groups_prefixed',
        EMPLOYEESVCURL: 'https://api.bekk.dev/employee-svc',
        EMPLOYEEPICTUREURL: 'https://res.cloudinary.com/bekkimg/w_384,h_512,c_fill/d_default_image.png/',
        SLACK_ERROR_HOOK_URL: '',
      }
    : (window.SERVER_DATA as ENV);

export const HOST = env.BACKEND_URL;
export const HOST_V2 = env.BACKEND_URL_V2;
export const authConfig = {
  AUDIENCE: env.AUDIENCE,
  ISSUERDOMAIN: env.ISSUERDOMAIN,
  SCOPES: env.SCOPES,
  EMPLOYEESVCURL: env.EMPLOYEESVCURL,
  EMPLOYEEPICTUREURL: env.EMPLOYEEPICTUREURL,
};
export const SLACK_ERROR_HOOK_URL = env.SLACK_ERROR_HOOK_URL;
export const ENVIRONMENT = env.ENVIRONMENT;
