import { isNumber, isString } from 'lodash';
import { atom, selector } from 'recoil';

import { Employee, EmployeeRecord } from '../types/employeeTypes';
import { getEmployeeId } from '../utils/auth';

import { getEmployees } from './employeeApi';

export const employeeRecordState = atom<EmployeeRecord>({
  key: 'EmployeeRecordState',
  default: selector({
    key: 'EmployeeRecord/Default',
    get: async () => {
      const employees = await getEmployees();
      const record: EmployeeRecord = {
        employees: employees,
        employeeIndexById: new Map<number, number>(),
        employeeIndexByName: new Map<string, number>(),
        employeeIndexByEmail: new Map<string, number>(),
        getEmployee: function (id?: string | number | null) {
          if (!id) {
            return undefined;
          }

          if (isNumber(id)) {
            const index = this.employeeIndexById.get(id);
            return index !== undefined ? this.employees[index] : undefined;
          }

          if (isString(id)) {
            const index = this.employeeIndexByEmail.get(id);
            return index !== undefined ? this.employees[index] : undefined;
          }
          return undefined;
        },
        getEmployeeByName: function (name: string | null) {
          if (!name) {
            return undefined;
          }
          const index = this.employeeIndexByName.get(name);
          return index !== undefined ? this.employees[index] : undefined;
        },
      };

      employees.forEach((employee, index) => {
        record.employeeIndexByEmail.set(employee.email, index);
        record.employeeIndexById.set(employee.id, index);
        record.employeeIndexByName.set(employee.name, index);
      });
      return record;
    },
  }),
});

export const loggedInEmployeeSelector = selector<Employee | undefined>({
  key: 'loggedInEmployeeSelector',
  get: ({ get }) => {
    const employeeID = getEmployeeId();
    const employeeRecord = get(employeeRecordState);
    return employeeRecord.getEmployee(employeeID);
  },
});
