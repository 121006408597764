import { getEmployeeId } from 'utils/auth';

import { Employee } from './employeeTypes';
import { MediaType } from './mediaTypes';

export type Contributor = number;

export interface Post {
  id: number;
  title: string;
  description: string;
  thumbnailUrl: string;
  published: string | null;
  isExternal: boolean;
  consumptionTime: number | null;
  url: string;
  created: string;
  podcastSeriesId?: number | null; //only for media type podcast
  playbackUrl?: string | null; // only for media type podcast and video
  mediaType: MediaType;
  tags: string[];
  publisherEmployee: Employee | null;
  contributorEmployees: Employee[];
  type: 'post';
  totalViews: number;
  isShareToBekkNo: boolean;
}

export interface PostBackend {
  id: number;
  title: string;
  description: string;
  thumbnail_url: string;
  published: string | null;
  publisher_id: number | null;
  is_external: boolean;
  consumption_time: number | null;
  url: string;
  tags: string[];
  created: string;
  podcast_series_id?: number | null; //only for media type podcast
  playback_url?: string | null; // only for media type podcast and video
  media_type: MediaType;
  contributors: Contributor[];
  type: 'postPreview';
  total_views: number;
  is_share_to_bekk_no: boolean;
}

export interface PostMetadata {
  id?: number;
  title: string;
  description: string;
  thumbnail_url: string;
  published: string | null;
  publisher_id: number;
  is_external: boolean;
  consumption_time: number | null;
  url: string;
  tags: string[];
  created?: string;
  media_type: MediaType;
  collection_ids: number[];
  contributors: Contributor[];
  total_views: number;
  is_share_to_bekk_no: boolean;
}

export interface PostBackendV2 {
  metadata: PostMetadata;
  type: 'post' | 'video' | 'podcast';
}

export interface CollectionPostBackend extends PostBackend {
  added_by: number;
  added_date: string;
}

// TODO: fjern denne og CreatePost når vi har fått alt over på riktig type
export const mapToPostMetadata = (post: CreatePost): PostMetadata => ({
  title: post.title,
  description: post.description,
  thumbnail_url: post.thumbnailUrl,
  published: post.published,
  publisher_id: getEmployeeId(),
  is_external: post.isExternal,
  consumption_time: post.consumptionTime,
  url: post.url,
  tags: post.tags,
  media_type: post.mediaType,
  collection_ids: post.collectionIds,
  contributors: post.contributors,
  total_views: 0,
  is_share_to_bekk_no: post.isShareToBekkNo,
});

export interface CreatePost {
  title: string;
  description: string;
  thumbnailUrl: string;
  published: string | null;
  publisherId: number | null;
  isExternal: boolean;
  consumptionTime: number | null;
  url: string;
  playbackUrl?: string | null; // only for media type podcast and video
  podcastSeriesId?: number | null; //only for media type podcast
  mediaType: MediaType;
  collectionIds: number[];
  contributors: Contributor[];
  tags: string[];
  isShareToBekkNo: boolean;
}

export interface CreatePostBackend {
  title: string;
  description: string;
  thumbnail_url: string;
  published: string | null;
  publisher_id: number | null;
  is_external: boolean;
  consumption_time: number | null;
  url: string;
  playback_url: string | null; // only for media type podcast and video
  podcast_series_id: number | null; // only for media type podcast
  tags: string[];
  deleted?: string;
  media_type: MediaType;
  collection_ids: number[];
  contributors: Contributor[];
  isShareToBekkNo: boolean;
}
