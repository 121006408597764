type ReconnectingWebSocketEvent = 'open' | 'message';

type ReconnectingWebSocketEventListener<T extends ReconnectingWebSocketEvent> = (
  // eslint-disable-next-line no-undef
  event: WebSocketEventMap[T]
) => void;

export class ReconnectingWebSocket extends EventTarget {
  private socket: WebSocket;
  private disposed = false;
  public readonly url: string;

  private listeners: Record<ReconnectingWebSocketEvent, ReconnectingWebSocketEventListener<any>[]> = {
    open: [],
    message: [],
  };

  constructor(url: string) {
    super();
    this.url = url;
    this.socket = this.createSocket();
    this.checkConnection();
  }

  private checkConnection() {
    if (this.disposed) return;

    if (this.socket.readyState === this.socket.CLOSED) {
      this.socket = this.createSocket();
    }
  }

  addEventListener<K extends ReconnectingWebSocketEvent>(type: K, listener: ReconnectingWebSocketEventListener<K>) {
    this.listeners[type].push(listener);
  }

  removeEventListener<K extends ReconnectingWebSocketEvent>(type: K, listener: ReconnectingWebSocketEventListener<K>) {
    const i = this.listeners[type].indexOf(listener);
    if (i >= 0) this.listeners[type].splice(i, 1);
  }

  private createSocket() {
    const ws = new WebSocket(this.url);

    ws.addEventListener('open', (event) => {
      this.listeners['open'].forEach((listener) => listener(event));
    });

    ws.addEventListener('message', (event) => {
      this.listeners['message'].forEach((listener) => listener(event));
    });

    ws.addEventListener('error', () => {
      if (ws.readyState === ws.OPEN) ws.close();
    });

    return ws;
  }

  send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.disposed || this.socket.readyState === this.socket.CLOSED) {
      this.socket = this.createSocket();
    }

    /* The WebSocket might be in the `CONNECTING` state (or any other than `OPEN`);
       in that case, just return and try again the next timeout.
     */
    if (this.socket?.readyState !== this.socket.OPEN) return;
    this.socket.send(data);
  }

  close() {
    this.listeners.message = [];
    this.listeners.open = [];
    this.socket?.close();
    this.disposed = true;
  }
}
