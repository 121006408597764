import { createBrowserHistory } from 'history';
import { getIdToken } from 'utils/auth';
import { HOST, HOST_V2 } from 'utils/config';
import { reportError } from 'utils/errorReporting';
import { RoutePaths } from 'utils/urls';
import { v4 as uuidv4 } from 'uuid';

export const authenticatedGet = async <T>(url: string, host: string = HOST): Promise<T> => {
  const accessToken = getIdToken();
  const response = await fetch(`${host}${url}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    if (response.status === 401 || response.status === 403) {
      createBrowserHistory().push(RoutePaths.UNAUTHORIZED);
      window.location.reload();
      return response.json();
    } else {
      const responseJson = await response.json();
      reportError(responseJson, url);
      throw responseJson;
    }
  }
};

export const authenticatedGetV2 = async <T>(url: string, host: string = HOST_V2): Promise<T> => {
  const options = generateOptions('GET');
  const response = await fetch(`${host}${url}`, { ...options });

  if (response.ok) {
    return response.json();
  } else {
    if (response.status === 401 || response.status === 403) {
      createBrowserHistory().push(RoutePaths.UNAUTHORIZED);
      window.location.reload();
      return response.json();
    } else {
      const responseJson = await response.json();
      reportError(responseJson, url);
      throw responseJson;
    }
  }
};

const createAuthenticatedV2RequestWithoutResponse =
  (method: 'POST' | 'PUT' | 'DELETE' | 'PATCH') =>
  async <U>(url: string, body: U, host: string = HOST_V2) => {
    const options = generateOptions(method);
    const response = await fetch(`${host}${url}`, { ...options, body: JSON.stringify(body) });

    if (response.ok) {
      return;
    } else {
      throw await response.json();
    }
  };

const createAuthenticatedV2RequestWithResponse =
  (method: 'POST' | 'PUT' | 'DELETE' | 'PATCH') =>
  async <U, T>(url: string, body: U, host: string = HOST_V2): Promise<T> => {
    const options = generateOptions(method);
    const response = await fetch(`${host}${url}`, { ...options, body: JSON.stringify(body) });

    if (response.ok) {
      return response.json();
    } else {
      throw await response.json();
    }
  };

const generateOptions = (method: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET') => ({
  method: method,
  headers: {
    Authorization: `Bearer ${getIdToken()}`,
    'Content-Type': 'application/json',
    'x-request-id': uuidv4(),
  },
});

const createAuthenticatedRequest =
  (method: string) =>
  async <T, U>(url: string, body: U, host: string = HOST): Promise<T> => {
    const accessToken = getIdToken();
    const response = await fetch(`${host}${url}`, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw await response.json();
    }
  };

export const authenticatedFileGet = async (url: string, host: string = HOST_V2) => {
  const options = generateOptions('GET');
  const response = await fetch(`${host}${url}`, { ...options });
  if (response.ok) {
    return response.blob();
  } else {
    const responseJson = await response.json();
    throw Error(responseJson.message);
  }
};

const createAuthenticatedFileRequest =
  (method: 'POST' | 'PUT' | 'DELETE') =>
  async <T>(
    url: string,
    body: FormData,
    contentType: 'application/json' = 'application/json',
    host: string = HOST_V2
  ): Promise<T> => {
    const accessToken = getIdToken();
    const response = await fetch(`${host}${url}`, {
      method: method,
      body: body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ContentType: contentType,
        'x-request-id': uuidv4(),
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      throw await response.json();
    }
  };

export const authenticatedDelete = async <T>(url: string, body: T, host: string = HOST): Promise<void> => {
  const accessToken = getIdToken();
  const response = await fetch(`${host}${url}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'x-request-id': uuidv4(),
    },
  });

  if (!response.ok) {
    const responseJson = await response.json();
    reportError(responseJson, url);
    throw responseJson;
  }
};

const createAuthenticatedDeleteV2WithResponse =
  (method: 'POST' | 'PUT' | 'DELETE' | 'PATCH') =>
  async <T>(url: string, body: T, host: string = HOST_V2): Promise<void> => {
    const options = generateOptions(method);
    const response = await fetch(`${host}${url}`, {
      ...options,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const responseJson = await response.json();
      reportError(responseJson, url);
      throw responseJson;
    }
  };

export const authenticatedPost = createAuthenticatedRequest('POST');
export const authenticatedPut = createAuthenticatedRequest('PUT');
export const authenticatedFilePost = createAuthenticatedFileRequest('POST');

export const authenticatedDeleteV2 = createAuthenticatedDeleteV2WithResponse('DELETE');
export const authenticatedV2PostWithoutResponse = createAuthenticatedV2RequestWithoutResponse('POST');
export const authenticatedV2PutWithoutResponse = createAuthenticatedV2RequestWithoutResponse('PUT');
export const authenticatedV2DeleteWithoutResponse = createAuthenticatedV2RequestWithoutResponse('DELETE');
export const authenticatedV2PatchWithoutResponse = createAuthenticatedV2RequestWithoutResponse('PATCH');

export const authenticatedV2PostWithResponse = createAuthenticatedV2RequestWithResponse('POST');

export type GenericSuccessResponse = {
  message: 'ok';
};

export type IdentifierResponse = {
  id: number;
};
