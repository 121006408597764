import { atom, selector, useRecoilValue } from 'recoil';

import { ReadingListMapping } from '../types/redingListTypes';

import { getReadingList } from './readingListApi';

export const useIsPostInReadingList = (): ((postId: number) => boolean) => {
  const readingList = useRecoilValue(readingListState);
  const readingListPostIds = readingList.map((readingListPost) => readingListPost.postId);
  return (postId: number) => {
    return readingListPostIds.includes(postId);
  };
};

export const readingListState = atom<ReadingListMapping[]>({
  key: 'ReadingListState',
  default: selector({ key: 'ReadingListState/Default', get: () => getReadingList() }),
});
