import { Employee } from 'types/employeeTypes';
import { getEmployeeIdEvenWithExpiredToken } from 'utils/auth';
import { ApiUrls } from 'utils/urls';

import {
  MixpanelEvent,
  MixpanelInitiateSearchBody,
  MixpanelNavigateToPageBody,
  MixpanelRequestBody,
} from '../types/mixpanelTypes';
import { authenticatedPost, authenticatedV2PostWithoutResponse } from '../utils/apiUtils';

export const trackEventToMixpanel = async (event: MixpanelEvent, employee: Employee) => {
  try {
    await authenticatedPost<undefined, MixpanelRequestBody>(ApiUrls.MIXPANEL, {
      id: employee.id,
      department: employee.department,
      event: event,
    });
  } catch (e) {
    console.error('mixpanel error:\n', e);
  }
};

export const trackPageNavigationToMixpanel = async (path: string, employee: Employee) => {
  try {
    await authenticatedV2PostWithoutResponse<MixpanelNavigateToPageBody>(ApiUrls.MIXPANEL_TRACK_PAGE, {
      department: employee.department,
      path: path,
    });
  } catch (e) {
    console.error('mixpanel error:\n', e);
  }
};

export const trackInitiatedSearch = async (event: MixpanelEvent, searchTerm: string, employee: Employee) => {
  try {
    await authenticatedPost<undefined, MixpanelInitiateSearchBody>(ApiUrls.MIXPANEL_TRACK_SEARCH, {
      id: employee.id,
      department: employee.department,
      event: event,
      searchTerm: searchTerm.toLocaleLowerCase().trim(),
    });
  } catch (e) {
    console.error('mixpanel error\n', e);
  }
};

export const trackRedirectToMixpanel = async () => {
  const employeeID = getEmployeeIdEvenWithExpiredToken();

  try {
    await authenticatedPost<undefined, { id: number | null }>(ApiUrls.MIXPANEL_REDIRECT_SLACK, {
      id: employeeID,
    });
  } catch (e) {
    console.error('mixpanel error:\n', e);
  }
};
