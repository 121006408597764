import { Tab } from 'landing/Landing';
import _ from 'lodash';
import { addMyHistoryMapping } from 'myPage/myHistory/utils/myHistoryApi';
import { useRecoilValue } from 'recoil';
import { CollectionDisplayBackend } from 'types/collectionTypes';
import { CompetencePlanPreview } from 'types/competencePlanTypes';
import { InteractionType } from 'types/myHistoryTypes';
import { Contributor, Post, PostBackend, PostBackendV2 } from 'types/postTypes';
import { PostMetadata } from 'types/postTypes';
import {
  IdentifierResponse,
  authenticatedDelete,
  authenticatedGet,
  authenticatedGetV2,
  authenticatedPost,
  authenticatedPut,
  authenticatedV2PostWithResponse,
  authenticatedV2PostWithoutResponse,
  authenticatedV2PutWithoutResponse,
} from 'utils/apiUtils';
import { getEmployeeId } from 'utils/auth';
import { ApiUrls } from 'utils/urls';
import { isNotUndefined } from 'utils/utils';

import { employeeRecordState } from '../../stateAndApi/employeeState';
import { Employee, EmployeeRecord } from '../../types/employeeTypes';
import {
  SearchResultBackend,
  SearchResultBackendWithSize,
  SearchResultMetadataWithSize,
  SearchSuggestionBackend,
} from '../../types/searchTypes';

import { UrlException, cleanPost, handleUrlException, isUrlException, mapFromMetadataToPostBackend } from './postUtils';

export const getPosts = async (): Promise<PostBackend[]> => {
  const posts = await authenticatedGet<PostBackend[]>(ApiUrls.GET_POSTS);
  const cleanedPosts = posts.map(cleanPost);
  return _.reverse(_.sortBy(cleanedPosts, ['published']));
};

interface FilterOptions {
  contentFilters: string[];
  isExternal: boolean | null;
}

interface SearchFeedRequestBody {
  limit: number;
  offset: number;
  filterOptions?: FilterOptions;
}

interface PostsByIdsRequestBody {
  postIds: number[];
}

export const getPostsBySearch = async (
  searchQuery: string,
  body: SearchFeedRequestBody,
  tab: Tab = Tab.Feed
): Promise<SearchResultBackendWithSize> => {
  const params = new URLSearchParams({ query: searchQuery });

  const getSearch = async (url: string) => {
    return await authenticatedV2PostWithResponse<SearchFeedRequestBody, SearchResultMetadataWithSize>(url, body);
  };

  const result = () => {
    if (tab === Tab.Kompetanseplan) return getSearch(`${ApiUrls.SEARCH_COMPETENCE_PLANS}?${params}`);
    else return getSearch(`${ApiUrls.SEARCH}?${params}`);
  };

  const { searchResult, size }: SearchResultMetadataWithSize = await result().then((result) => result);
  const searchResultBackend: SearchResultBackend[] = searchResult.map((pc) => {
    if (pc.post) {
      return mapFromMetadataToPostBackend(pc.post);
    } else if (pc.collection) {
      return { ...pc.collection, type: 'collectionPreview' } as CollectionDisplayBackend;
    } else {
      return {
        ...pc.competencePlan,
        type: 'competencePlanPreview',
      } as CompetencePlanPreview;
    }
  });

  return { searchResultBackend, size };
};

export const getOnePost = async (id: number): Promise<PostBackend> => {
  const url = ApiUrls.GET_POST + `/${id}`;
  return await authenticatedGet<PostBackend>(url);
};

export const getPostsByPodcastSeriesId = async (id: number | null | undefined): Promise<PostBackend[]> => {
  const url = ApiUrls.GET_POSTS_BY_PODCAST_SERIES_ID + `/${id}`;
  return await authenticatedGet<PostBackend[]>(url);
};

export const countPosts = async (): Promise<number> => {
  return await authenticatedGetV2<number>(ApiUrls.COUNT_POSTS);
};

export const getPostsForFeed = async (limit: number, offset: number): Promise<PostBackend[]> => {
  const params = new URLSearchParams({ limit: limit.toString(), offset: offset.toString() });
  const posts = await authenticatedGetV2<PostBackendV2[]>(`${ApiUrls.GET_POST_PAGE}?${params}`);

  const cleanedPosts = posts.map((p) => cleanPost(mapFromMetadataToPostBackend(p.metadata)));

  return _.reverse(_.sortBy(cleanedPosts, ['published']));
};

export const getRecommendedPostsForFeed = async (userDepartment: string, limit: number): Promise<PostBackend[]> => {
  const params = new URLSearchParams({ department: userDepartment, limit: limit.toString() });
  const posts = await authenticatedGetV2<PostBackendV2[]>(`${ApiUrls.GET_RECOMMENDED_POSTS}?${params}`);
  const cleanedPosts = posts.map((post) => mapFromMetadataToPostBackend(post.metadata)).map(cleanPost);
  return cleanedPosts;
};

export const getContentSuggestions = async (
  searchQuery: string,
  tab: Tab = Tab.Feed
): Promise<SearchSuggestionBackend> => {
  const params = new URLSearchParams({ query: searchQuery, filter: tab });
  return await authenticatedGetV2<SearchSuggestionBackend>(`${ApiUrls.SEARCH_SUGGESTION_CONTENT}?${params}`);
};

export const getMostRecentPosts = async (): Promise<PostBackend[]> => {
  const posts = await authenticatedGet<PostBackend[]>(`${ApiUrls.GET_MOST_RECENT_POSTS}?numberToFetch=100`);
  const cleanedPosts = posts.map(cleanPost);
  return _.reverse(_.sortBy(cleanedPosts, ['published']));
};

export const getPostsByIds = async (postIds: number[]): Promise<PostBackend[]> => {
  const posts = await authenticatedPost<PostBackend[], PostsByIdsRequestBody>(ApiUrls.GET_POSTS_BY_IDS, { postIds });
  const cleanedPosts = posts.map(cleanPost);
  return _.reverse(_.sortBy(cleanedPosts, ['published']));
};

export const getPostsByTag = async (tag: string, limit: number): Promise<PostBackend[]> => {
  const params = new URLSearchParams({ tag: tag, limit: limit.toString() });
  const posts = await authenticatedGetV2<PostBackendV2[]>(`${ApiUrls.GET_POSTS_BY_TAG}?${params}`);
  const cleanedPosts = posts.map((p) => cleanPost(mapFromMetadataToPostBackend(p.metadata)));
  return _.reverse(_.sortBy(cleanedPosts, ['published']));
};

export const getSpeedRead = async (): Promise<PostBackend> => {
  const post = await authenticatedGetV2<PostBackendV2>(ApiUrls.GET_SPEED_READ);
  return cleanPost(mapFromMetadataToPostBackend(post.metadata));
};

export const useCreatePost = (): ((newPost: PostMetadata) => Promise<IdentifierResponse | UrlException>) => {
  return async (newPost) => {
    newPost.publisher_id = getEmployeeId();
    const response = await createPost(newPost);

    if (isUrlException(response)) {
      const urlException = response as UrlException;
      throw handleUrlException(urlException);
    }

    addMyHistoryMapping({
      post_id: response.id,
      contributors: newPost.contributors,
      interaction_type: InteractionType.CONTRIBUTED,
      user_id: getEmployeeId(),
    });
    createPostProfile({ ...newPost, id: response.id });

    return response;
  };
};

const createPost = async (post: PostMetadata): Promise<IdentifierResponse | UrlException> => {
  return authenticatedV2PostWithResponse<PostMetadata, IdentifierResponse | UrlException>(ApiUrls.POST_POST, post);
};

export const useUpdatePost = (): ((updatedPost: Post) => Promise<Post>) => {
  const employeeRecord = useRecoilValue(employeeRecordState);
  return async (updatedPost) => {
    const response = await updatePost(mapToBackendPost(updatedPost));
    await updatePostProfile(mapToBackendPost(updatedPost));
    return mapToFrontendPost(response, employeeRecord);
  };
};

const updatePost = async (updatedPost: PostBackend): Promise<PostBackend> => {
  return authenticatedPut(ApiUrls.EDIT_POST, updatedPost);
};

const createPostProfile = async (updatedPost: PostMetadata) => {
  return authenticatedV2PostWithoutResponse(ApiUrls.POST_RECOMMENDER_PROFILE, {
    id: updatedPost.id,
    tags: updatedPost.tags,
    mediaType: updatedPost.media_type,
  });
};

const updatePostProfile = async (updatedPost: PostBackend) => {
  return authenticatedV2PutWithoutResponse(ApiUrls.POST_RECOMMENDER_PROFILE, {
    id: updatedPost.id,
    tags: updatedPost.tags,
    mediaType: updatedPost.media_type,
  });
};

export const useSafeDeletePost = (): ((postId: number) => Promise<void>) => {
  return async (postId: number) => {
    await authenticatedDelete(ApiUrls.DELETE_POST, { id: postId });
  };
};

const mapToContributors = (employees: Employee[]) => employees.map((e) => e.id);

export const mapToFrontendPosts = (posts: PostBackend[], employeeRecord: EmployeeRecord): Post[] => {
  return posts.map((p) => mapToFrontendPost(p, employeeRecord));
};

export const mapToFrontendPost = (post: PostBackend, employeeRecord: EmployeeRecord): Post => ({
  id: post.id,
  title: post.title,
  description: post.description,
  thumbnailUrl: post.thumbnail_url,
  created: post.created,
  published: post.published,
  mediaType: post.media_type,
  isExternal: post.is_external,
  url: post.url,
  consumptionTime: post.consumption_time,
  tags: post.tags,
  playbackUrl: post.playback_url,
  podcastSeriesId: post.podcast_series_id,
  publisherEmployee: mapToEmployee(post.publisher_id, employeeRecord),
  contributorEmployees: mapToEmployees(post.contributors, employeeRecord),
  type: 'post',
  totalViews: post.total_views,
  isShareToBekkNo: post.is_share_to_bekk_no,
});

export const mapToBackendPost = (post: Post): PostBackend => ({
  id: post.id,
  title: post.title,
  description: post.description,
  thumbnail_url: post.thumbnailUrl,
  published: post.published || null,
  publisher_id: post.publisherEmployee?.id || null,
  is_external: post.isExternal,
  consumption_time: post.consumptionTime || null,
  url: post.url,
  tags: post.tags,
  created: post.created,
  podcast_series_id: post.podcastSeriesId || null,
  playback_url: post.playbackUrl || null,
  media_type: post.mediaType,
  contributors: mapToContributors(post.contributorEmployees),
  type: 'postPreview',
  total_views: post.totalViews,
  is_share_to_bekk_no: post.isShareToBekkNo,
});

export const mapToEmployees = (contributors: Contributor[], employeeRecord: EmployeeRecord) =>
  contributors.map((c) => employeeRecord.getEmployee(c)).filter(isNotUndefined);

export const mapToEmployee = (publisher_id: number | null, employeeRecord: EmployeeRecord) =>
  employeeRecord.getEmployee(publisher_id) || null;
