import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'App';
import moment from 'moment';
import 'moment/locale/nb';
import { RecoilRoot } from 'recoil';
import DataLoadingErrorBoundary from 'shared/DataLoadingErrorBoundary';
import { Loader } from 'shared/Loader';
import { NotificationProvider } from 'shared/notification/NotificationProvider';
import { createGlobalStyle, css } from 'styled-components/macro';
import { authenticate, isAuthenticated } from 'utils/auth';
import { Theme, bekkColors, themes } from 'utils/colors';

import { RoutePaths } from './utils/urls';

import './index.css';

const GlobalStyle = createGlobalStyle<{ currentTheme: Theme }>`
  :root {
    ${bekkColors}
    ${(props) =>
      props.currentTheme &&
      css`
        --primary: ${props.currentTheme.primary};
        --contrast: ${props.currentTheme.contrast};
        --lighter-primary: ${props.currentTheme.lighterPrimary};
        --previous-primary: ${props.currentTheme.previousPrimary};
        --previous-contrast: ${props.currentTheme.previousContrast};
        --previous-ligther-primary: ${props.currentTheme.previousLighterPrimary};
      `};
  }
`;

const init = async () => {
  moment.locale('nb');

  const currentTheme = themes.find((theme) => {
    const now = moment().format('HH:mm');
    return now >= theme.from && now <= theme.to;
  });

  if (!isAuthenticated() && !window.location.pathname.includes(RoutePaths.REDIRECT)) {
    authenticate();
  } else {
    const container = document.getElementById('root');
    const root = createRoot(container!); // createRoot(container!) if you use TypeScript

    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <HelmetProvider>
            <RecoilRoot>
              <DataLoadingErrorBoundary>
                <React.Suspense fallback={<Loader />}>
                  <GlobalStyle currentTheme={currentTheme ?? themes[1]} />
                  <NotificationProvider>
                    <App />
                  </NotificationProvider>
                </React.Suspense>
              </DataLoadingErrorBoundary>
            </RecoilRoot>
          </HelmetProvider>
        </BrowserRouter>
      </React.StrictMode>
    );
  }
};

init();
