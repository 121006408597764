export enum InteractionType {
  VISITED = 'VISITED',
  PLAYED = 'PLAYED',
  SHARED = 'SHARED',
  CONTRIBUTED = 'CONTRIBUTED',
}

export enum ContributorAction {
  ADD = 'add',
  DELETE = 'delete',
}

export interface MyHistoryMapping {
  title: string;
  publisherId: number;
  postId: number;
  contributors: number[];
  thumbnailUrl: string;
  dateAdded: Date;
  interactionType: InteractionType;
}

export interface MyHistoryMappingBackend {
  title: string;
  publisher_id: number;
  post_id: number;
  contributors: number[];
  thumbnail_url: string;
  date_added: string;
  interaction_type: InteractionType;
}

export interface MyHistoryContributor {
  userId: number;
  action: ContributorAction;
}

export interface CreateMyHistoryMappingBackend {
  user_id: number;
  post_id: number;
  interaction_type: InteractionType;
  contributors: number[];
}
