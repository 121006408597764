import { useRecoilValue } from 'recoil';
import { getEmployeeId } from 'utils/auth';
import { ApiUrls } from 'utils/urls';

import { Employee } from '../types/employeeTypes';
import { authenticatedGetV2 } from '../utils/apiUtils';

import { employeeRecordState } from './employeeState';

export async function getEmployees(): Promise<Employee[]> {
  return await authenticatedGetV2(ApiUrls.EMPLOYEES);
}

export const useLoggedInEmployee = (): Employee => {
  const employeeID = getEmployeeId();
  const employeeRecord = useRecoilValue(employeeRecordState);
  const loggedInEmployee = employeeRecord.getEmployee(employeeID);

  if (loggedInEmployee === undefined) {
    throw Error('LoggedInEmployee was undefined, this should never happen.');
  }

  return loggedInEmployee;
};

export const useSingleEmployee = (id: number): Employee | undefined => {
  const employeeRecord = useRecoilValue(employeeRecordState);
  return employeeRecord.getEmployee(id);
};
