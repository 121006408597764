import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import Icon from 'shared/Icon';
import AchievementIcon, { AchievementDescription } from 'shared/IconAchievement';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { useGoToRoute } from 'stateAndApi/history';
import styled, { css } from 'styled-components/macro';
import { AchievementMessage } from 'types/achievementTypes';
import { ReconnectingWebSocket } from 'utils/ReconnectionWebSocket';
import { tabletBreakpoint } from 'utils/constants';
import { RoutePaths } from 'utils/urls';

import { HOST } from './utils/config';

export const AchievementSocket = () => {
  const [achievementMessage, setAchievementMessage] = useState<AchievementMessage | undefined>(undefined);

  useEffect(() => {
    const prot = process.env.NODE_ENV === 'development' ? 'ws' : 'wss';
    const url = HOST.split('//')[1];
    const ws = new ReconnectingWebSocket(`${prot}://${url}/ws`);
    let interval: any = undefined;

    ws.addEventListener('open', () => {
      const token = localStorage.getItem('userToken') as string;
      ws.send(token);

      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(() => ws.send('ping'), 15 * 1000);
    });

    ws.addEventListener('message', (event) => {
      if (event.data === 'pong') {
        return;
      }

      const message = JSON.parse(event.data);

      setAchievementMessage({
        id: message.id,
        title: message.title,
        description: message.description,
        date: message.date,
      });
    });

    return () => {
      ws.close();
    };
  }, [setAchievementMessage]);

  const onClose = useCallback(() => {
    setAchievementMessage(undefined);
  }, [setAchievementMessage]);

  if (!achievementMessage) return null;

  return <AchievementPopup achievement={achievementMessage} onClose={onClose} />;
};

const AchievementPopup = (props: { achievement: AchievementMessage; onClose: () => void }) => {
  const loggedInEmployee = useLoggedInEmployee();
  const goToRoute = useGoToRoute();
  const [overlayRef, setOverlayRef] = useState<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    goToRoute(RoutePaths.MY_PAGE_ACHIEVEMENTS);
    props.onClose();
  };

  useEffect(() => {
    if (!overlayRef) return;
    const onWheel = (event: WheelEvent) => event.preventDefault();
    overlayRef.addEventListener('wheel', onWheel, { passive: true });
    return () => {
      overlayRef.removeEventListener('wheel', onWheel);
    };
  }, [overlayRef]);

  useEffect(() => {
    if (props.achievement) setIsOpen(true);
  }, [props]);

  return (
    <Modal
      overlayRef={setOverlayRef}
      isOpen={isOpen}
      contentElement={(props, children) => <ModalStyle {...props}>{children}</ModalStyle>}
    >
      <CloseButtonContainer>
        <CloseButton onClick={props.onClose}>
          <Icon name="cross" />
        </CloseButton>
      </CloseButtonContainer>

      <AchievementContainer>
        <Header>Gratulerer {loggedInEmployee.firstName} 🎉 </Header>
        <Text>✨✨✨ Ny bragd! ✨✨✨</Text>

        <AchievementIconWrappper>
          <StyledIcon
            title={props.achievement.title}
            description={props.achievement.description as AchievementDescription}
          />
          <Description> {props.achievement.description} </Description>
        </AchievementIconWrappper>

        <GoToAchievementsButton onClick={handleClick}>Se dine bragder 🏆</GoToAchievementsButton>
      </AchievementContainer>
    </Modal>
  );
};

const FadeInAndPopAnimation = css`
  @keyframes fadeIn {
    0% {
      transform: scale(0.5);
      visibility: visible;
    }
    50% {
      transform: scale(1.3);
      visibility: visible;
    }

    100% {
      transform: scale(1);
      visibility: visible;
    }
  }

  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const FadeInAnimation = css`
  @keyframes fadeInOutAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fadeInOutAnimation 2s;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
`;

const MovingFromTopAnimation = css`
  @keyframes moving {
    from {
      transform: translateY(-200px);
    }
    to {
      transform: translateY(0px);
    }
  }
  animation: moving 1s;
`;

const TextStyle = css`
  white-space: nowrap;
  color: var(--morgendis);
  font-family: var(--font-family-cardtitle);
  font-size: 1rem;

  @media (min-width: ${tabletBreakpoint}) {
    font-size: 1.5rem;
  }
`;

const ModalStyle = styled.div`
  display: 'fixed' !important;
  height: fit-content;
  width: auto;
  max-width: 90%;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
  padding: 0 !important;
  box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  &:hover,
  &:focus-visible {
    cursor: pointer;
  }
`;

const AchievementContainer = styled.div`
  font-family: var(--font-family-cardtitle);
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 4rem 2rem 4rem;
  gap: 1rem;
`;

const StyledIcon = styled(AchievementIcon)`
  width: 10rem;
  height: 10rem;
  margin: 1rem;
  visibility: hidden;
  ${FadeInAndPopAnimation}
`;

const Header = styled.h1`
  ${TextStyle}
  margin: 0;
  font-weight: bold;
  ${MovingFromTopAnimation}
`;

const Text = styled.div`
  ${TextStyle}
  ${FadeInAnimation}
`;

const AchievementIconWrappper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.div`
  ${TextStyle}
  ${FadeInAnimation}
`;

const GoToAchievementsButton = styled.button`
  font-family: var(--font-family-cardtitle);
  border: 1px solid var(--primary);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  transition: color 0.2s, background 0.2s, padding 0.2s;
  background: var(--primary);
  cursor: pointer;
  ${FadeInAnimation}

  &:hover {
    background: var(--hvit);
    color: var(--sort);
  }
`;
