import React, { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useSubmitForm } from 'createNewPost/form/useSubmitForm';
import { NarrowInput } from 'post/editPost/EditPost';
import { uploadFile } from 'post/utils/fileApi';
import { getOnePost, mapToFrontendPost } from 'post/utils/postApi';
import { isUrlException } from 'post/utils/postUtils';
import { useRecoilValue } from 'recoil';
import Button from 'shared/button/Button';
import InputText from 'shared/input/InputText';
import { SummaryInput } from 'shared/input/SummaryInput';
import { ContributorsInput } from 'shared/input/postInput/ContributorsInput';
import { Image, ImageSelect } from 'shared/input/postInput/ImageSelect';
import InputNumber from 'shared/input/postInput/InputNumber';
import { TagsFormField } from 'shared/input/postInput/TagsFormField';
import styled from 'styled-components/macro';
import { CollectionBackend, CollectionPost } from 'types/collectionTypes';
import { CreatePost, PostBackend, mapToPostMetadata } from 'types/postTypes';
import { fadeAnimation } from 'utils/commonAnimations';
import { tabletBreakpoint } from 'utils/constants';
import { preventDefaultBehaviourEnter } from 'utils/eventListeners';
import { getErrorMessage, isNotUndefined } from 'utils/utils';

import { addPostToCollection } from '../collection/utils/collectionApi';
import { useCreatePost } from '../post/utils/postApi';
import { Form, FormInnerOuterContainer, FormOuterContainer } from '../shared/FormComponents';
import Icon from '../shared/Icon';
import { CATEGORY_TAG } from '../shared/input/postInput/CategoryTagPicker';
import { useNotification } from '../shared/notification/useNotification';
import { useLoggedInEmployee } from '../stateAndApi/employeeApi';
import { employeeRecordState } from '../stateAndApi/employeeState';
import { useGoToRoute } from '../stateAndApi/history';
import { trackEventToMixpanel } from '../stateAndApi/mixpanelApi';
import { MediaType } from '../types/mediaTypes';
import { MixpanelEvent } from '../types/mixpanelTypes';
import { RoutePaths } from '../utils/urls';

import { ContentPicker } from './form/ContentPicker';
import FileUploader from './form/FileUploader';
import { UrlScreening } from './form/UrlScreening';
import { allFieldsDefaultValues } from './form/formState';

import ProgressTracker from './ProgressTracker';
import ShareToSlackInfo from './ShareToSlackInfo';

interface Props {
  collection?: CollectionBackend;
  setPostAdded?: Dispatch<SetStateAction<boolean>>;
  setCollection?: (collection: CollectionBackend) => void;
}

const CreateNewPost = ({ collection, setPostAdded, setCollection }: Props) => {
  const employeeRecord = useRecoilValue(employeeRecordState);
  const loggedInEmployee = useLoggedInEmployee();
  const createPost = useCreatePost();
  const [originalImage, setOriginalImage] = useState('');
  const [urlScreened, setUrlScreened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const { notifyInfo, notifyWarning } = useNotification();
  const goToRoute = useGoToRoute();

  const [postState, setPostState] = useState<CreatePost>(allFieldsDefaultValues);

  const onTagsChange = (newTags: string[]) => {
    const newPostState = {
      ...postState,
      tags: newTags,
    };
    setPostState(newPostState);
  };

  const onConsumptionTimeChange = (consumptionTime: number) => {
    const newPostState = {
      ...postState,
      consumptionTime: consumptionTime > 0 ? consumptionTime * 60 : null,
    };
    setPostState(newPostState);
  };

  const onMediaTypeChange = (mediaType: MediaType) => {
    const newPostState = {
      ...postState,
      mediaType: mediaType,
    };
    setPostState(newPostState);
  };

  const resetThumbnail = async (event: FormEvent) => {
    event.preventDefault();
    const newPostState = {
      ...postState,
      thumbnailUrl: originalImage,
    };
    setPostState(newPostState);
  };

  const setThumbnail = (s: string) => {
    const newPostState = {
      ...postState,
      thumbnailUrl: s,
    };
    setPostState(newPostState);
  };

  const setTitle = (title: string) => {
    const newPostState = {
      ...postState,
      title: title,
    };
    setPostState(newPostState);
  };

  const setDescription = (description: string) => {
    const newPostState = {
      ...postState,
      description: description,
    };
    setPostState(newPostState);
  };

  const setIsShareToBekkNo = (isShareToBekkNo: boolean) => {
    const newPostState = {
      ...postState,
      isShareToBekkNo: isShareToBekkNo,
    };
    setPostState(newPostState);
  };

  const checkUrlIsEmptyOrFileIsEmpty = () => {
    const urlError = 'Innlegget må ha en URL eller en fil';
    if (postState.url === '' && !selectedFile) throw new Error(urlError);
  };

  const checkTitleIsEmpty = () => {
    if (postState.title === '') throw new Error('Innlegget må ha en tittel.');
  };

  const onChangeContributors = (contributors: string[]) => {
    const contributorIds = contributors.map((e) => employeeRecord.getEmployeeByName(e)?.id);
    if (contributorIds !== undefined) {
      const newPostState = {
        ...postState,
        contributors: contributorIds as number[],
      };
      setPostState(newPostState);
    }
  };

  const addNewPostToCollection = async (post: PostBackend) => {
    if (!!collection && !!setCollection) {
      try {
        const frontendPost = mapToFrontendPost(post, employeeRecord);
        const collectionPosts = collection.posts;
        const collectionPost: CollectionPost = {
          addedBy: loggedInEmployee.id,
          addedDate: new Date().toISOString(),
          post: {
            ...frontendPost,
            total_views: frontendPost.totalViews,
            thumbnail_url: frontendPost.thumbnailUrl,
            consumption_time: frontendPost.consumptionTime,
            collection_ids: [],
            is_external: frontendPost.isExternal,
            media_type: frontendPost.mediaType,
            publisher_id: frontendPost.publisherEmployee?.id!!,
            contributors: frontendPost.contributorEmployees.map((e) => e.id),
            is_share_to_bekk_no: frontendPost.isShareToBekkNo,
          },
        };
        collectionPosts.push(collectionPost);

        setCollection({ ...collection, posts: collectionPosts });

        await addPostToCollection(collection.metadata.id!!, post.id);
        trackEventToMixpanel(MixpanelEvent.ADD_POST_TO_COLLECTION_FROM_COLLECTION, loggedInEmployee);
      } catch (error) {
        notifyWarning('OBS', getErrorMessage(error));
      }
    }
  };

  const categoryTagsIsIncluded = (): boolean => {
    return CATEGORY_TAG.filter((catTag) => postState.tags.includes(catTag)).length > 0;
  };

  const onFileChange = (file: File) => {
    setSelectedFile(file);
    setPostState({
      ...postState,
      mediaType: 'file',
      thumbnailUrl: 'https://fag-i-bekk-v2.s3.eu-west-1.amazonaws.com/bambus.png',
    });
  };

  const submit = async () => {
    if (!categoryTagsIsIncluded()) {
      notifyWarning('Ooops!', 'Det ser ut til at du har glemt å tagge et fagområde 😬');
      return;
    }

    let response;
    const isExternal = postState.contributors.length === 0;
    if (selectedFile) {
      const { name } = await uploadFile(selectedFile);
      response = await createPost(mapToPostMetadata({ ...postState, isExternal, url: name }));
    } else {
      response = await createPost(mapToPostMetadata({ ...postState, isExternal }));
    }

    if (isUrlException(response)) {
      return;
    }

    const statusMessage = 'Innlegget ble opprettet 🎉';

    if (collection?.metadata.id && setPostAdded) {
      await addNewPostToCollection(await getOnePost(response.id));
      setPostAdded(true);
      notifyInfo('Hurra!', statusMessage, 5000);
    } else {
      notifyInfo('Hurra!', statusMessage, 5000);
      goToRoute(RoutePaths.LANDING);
    }
  };

  const validateForm = () => {
    checkUrlIsEmptyOrFileIsEmpty();
    checkTitleIsEmpty();
  };

  const handleBackButtonClick = () => {
    if (collection?.metadata.id && setPostAdded) {
      setPostAdded(true);
    } else {
      goToRoute(RoutePaths.LANDING);
    }
  };

  const secondsToMinutes = (seconds: number) => {
    return Math.ceil(seconds / 60);
  };

  const { isSubmitting, setSubmitting, submitForm } = useSubmitForm(submit, validateForm);

  let timeInputLabel: string;
  if (postState.mediaType === 'podcast' || postState.mediaType === 'video') {
    timeInputLabel = 'Avspillingstid i minutter';
  } else {
    timeInputLabel = 'Lesetid i minutter';
  }

  return (
    <FormOuterContainer>
      <Helmet>
        <title>Atlas - Del innhold</title>
      </Helmet>
      <FormInnerOuterContainer>
        <BackButton onClick={handleBackButtonClick}>
          <ArrowIcon />
        </BackButton>
        <ProgressTracker
          urlScreened={urlScreened}
          requiredFieldsCompleted={postState.title !== '' && categoryTagsIsIncluded()}
        />
        <h1>Del innhold</h1>

        <Form onSubmit={submitForm} onKeyDown={preventDefaultBehaviourEnter}>
          <UrlScreening
            setValue={setPostState}
            state={postState}
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            setUrlScreened={setUrlScreened}
            urlScreened={urlScreened}
            setOriginalImage={setOriginalImage}
          />
          <>
            <h4>Eller</h4>
            <FileUploader selectedFile={selectedFile} onFileChange={onFileChange} />
          </>
          {(urlScreened || selectedFile) && (
            <>
              <FadeDiv>
                <ContentPicker value={postState.mediaType} onChange={(mediaType) => onMediaTypeChange(mediaType)} />
                {postState.mediaType === 'video' ? (
                  <Image src={postState.thumbnailUrl} alt="" />
                ) : (
                  <ImageSelect
                    resetThumbnail={resetThumbnail}
                    editedThumbnail={postState.thumbnailUrl}
                    setEditedThumbnail={setThumbnail}
                    showThumbnail={showThumbnail}
                    setShowThumbnail={setShowThumbnail}
                  />
                )}
                <InputText label="Tittel (obligatorisk)" value={postState.title} onChange={(s) => setTitle(s)} />
                <SummaryInput label="Beskrivelse" value={postState.description} onChange={(s) => setDescription(s)} />
                <ContributorsInput
                  value={postState.contributors.map((c) => employeeRecord.getEmployee(c)?.name).filter(isNotUndefined)}
                  onChange={onChangeContributors}
                />
                {['video', 'podcast', 'article'].includes(postState.mediaType) && (
                  <NarrowInputNoMargin>
                    <InputNumber
                      label={timeInputLabel}
                      value={postState.consumptionTime ? secondsToMinutes(postState.consumptionTime) : null}
                      onChange={onConsumptionTimeChange}
                    />
                  </NarrowInputNoMargin>
                )}
                <div>
                  <Label>Videredeling</Label>
                  <CheckBoxDescription disabled={isSubmitting}>
                    <CheckBox
                      type="checkbox"
                      color="var(--contrast)"
                      disabled={false}
                      checked={postState.isShareToBekkNo}
                      onChange={() => setIsShareToBekkNo(!postState.isShareToBekkNo)}
                    />
                    <Checkmark visible={postState.isShareToBekkNo} />
                    Er det greit at innlegget deles til Bekk.no?
                  </CheckBoxDescription>
                </div>
                <TagsFormField
                  onChange={onTagsChange}
                  existingTags={postState.tags}
                  description={postState.description}
                  title={postState.title}
                />

                <ShareToSlackInfo />

                <SubmitButton type="submit" disabled={isSubmitting}>
                  Del innlegg
                </SubmitButton>
              </FadeDiv>
            </>
          )}
        </Form>
      </FormInnerOuterContainer>
    </FormOuterContainer>
  );
};

export default CreateNewPost;

const FadeDiv = styled.div`
  margin-top: var(--4);
  display: flex;
  flex-direction: column;
  gap: var(--4);
  animation: ${fadeAnimation};
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const NarrowInputNoMargin = styled(NarrowInput)`
  margin: 0;
`;

const BackButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0 0 8px;
  margin-left: -8px;
`;

const ArrowIcon = styled(Icon).attrs({ name: 'arrow-long' })`
  width: 100%;
  height: 100%;
  transition: transform 0.2s;

  ${BackButton}:hover &,
  ${BackButton}:focus-visible & {
    transform: translateX(-8px);
  }
`;

export const CheckBox = styled.input<{ color: string; disabled: boolean }>`
  position: relative;
  border: var(--1px) solid var(--sort);
  width: var(--16px-rem);
  height: var(--16px-rem);
  appearance: none;
  background: inherit;
  margin-right: var(--1-05);
  margin-left: 0;
  overflow: hidden;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--1px));
    left: calc(-1 * var(--1px));
    width: var(--16px-rem);
    height: var(--16px-rem);
    background: ${({ color }) => color};
    transition: transform 0.2s;
    transform: scale(0);
  }

  &:checked:after {
    transform: scale(1);
  }

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--12px-rem);
    height: var(--12px-rem);
  }
`;

export const Checkmark = styled(Icon).attrs({ name: 'checkmark' })<{ visible: boolean }>`
  position: absolute;
  width: var(--16px-rem);
  height: var(--16px-rem);
  left: 1px;
  top: 3px;
  transition: stroke 0.2s;
  stroke: ${(props) => (props.visible ? 'var(--sort)' : 'transparent')};

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--12px-rem);
    height: var(--12px-rem);
  }
`;

export const CheckBoxDescription = styled.label<{ disabled: boolean }>`
  display: flex;
  position: relative;
  font-family: var(--din-light);
  font-size: var(--16px-rem);
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1.0')};
  user-select: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  input {
    margin-left: var(--1px);
  }

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--12px-rem);
    input {
      margin-right: var(--1);
    }
  }
`;

export const Label = styled.div`
  color: var(--morgendis);
  margin-bottom: var(--05);
  font-size: 14px;
  display: block;
  font-family: var(--din-light), sans-serif;
`;
