import React from 'react';

import { InternalLink } from 'shared/InternalLink';
import styled from 'styled-components/macro';

import { LogoAndOptionsHeader } from '../landing/header/GenericHeader';
import { fadeAnimation } from '../utils/commonAnimations';
import { contentWidth, mobileBreakpoint } from '../utils/constants';
import { RoutePaths } from '../utils/urls';

const Container = styled.div`
  background: var(--primary);
  display: flex;
  flex-direction: column;
  max-width: ${contentWidth};
  margin: 0 auto;
  padding: var(--8) var(--4) var(--8);
  gap: var(--6);
  animation: ${fadeAnimation};

  @media (max-width: ${mobileBreakpoint}) {
    padding: var(--4) var(--2);
    gap: var(--3);
  }
`;

const ErrorMessageContainer = styled.div`
  gap: var(--1);
  font-family: var(--newzald-book);
  font-size: var(--18px-rem);
`;

const ErrorMessage = styled.h2`
  display: flex;
  justify-content: center;
`;

const Info = styled.p`
  font-size: var(--24px-rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
`;

export const NotFoundPage = () => {
  return (
    <Container>
      <LogoAndOptionsHeader />
      <ErrorMessageContainer>
        <ErrorMessage>404</ErrorMessage>
        <Info>
          <span>Oisann, her var det tomt 🤔</span>
          <InternalLink to={RoutePaths.LANDING}>Tilbake til forsiden</InternalLink>
        </Info>
      </ErrorMessageContainer>
    </Container>
  );
};
