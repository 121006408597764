import { useSetRecoilState } from 'recoil';

import { NewReadingListMappingBackend, ReadingListMapping, ReadingListMappingBackend } from '../types/redingListTypes';
import {
  authenticatedGetV2,
  authenticatedV2DeleteWithoutResponse,
  authenticatedV2PostWithoutResponse,
} from '../utils/apiUtils';
import { getEmployeeId } from '../utils/auth';
import { ApiUrls } from '../utils/urls';

import { readingListState } from './readingListState';

export const getReadingList = async (): Promise<ReadingListMapping[]> => {
  const response = await authenticatedGetV2<ReadingListMappingBackend[]>(ApiUrls.READING_LIST);
  return response.map(mapToReadingListMappingFrontend);
};

export const useAddPostToReadingList = (): ((postId: number) => Promise<void>) => {
  const setReadingListState = useSetRecoilState(readingListState);

  return async (postId: number) => {
    const employeeId = getEmployeeId();
    const mappingToAdd = mapToNewReadingListMappingBackend(postId, employeeId);
    await authenticatedV2PostWithoutResponse(`${ApiUrls.READING_LIST}/${mappingToAdd.post_id}`, {});
    const newReadingList = await getReadingList();
    setReadingListState(newReadingList);
  };
};

export const useDeletePostFromReadingList = (): ((postId: number) => Promise<void>) => {
  const setReadingListState = useSetRecoilState(readingListState);

  return async (postId: number) => {
    const employeeId = getEmployeeId();
    const mappingToDelete = mapToNewReadingListMappingBackend(postId, employeeId);
    await authenticatedV2DeleteWithoutResponse(`${ApiUrls.READING_LIST}/${mappingToDelete.post_id}`, {});
    const newReadingList = await getReadingList();
    setReadingListState(newReadingList);
  };
};

const mapToNewReadingListMappingBackend = (postId: number, employeeId: number): NewReadingListMappingBackend => ({
  user_id: employeeId,
  post_id: postId,
});

const mapToReadingListMappingFrontend = (readingListMappingBackend: ReadingListMappingBackend): ReadingListMapping => ({
  userId: readingListMappingBackend.user_id,
  postId: readingListMappingBackend.post_id,
  dateAdded: readingListMappingBackend.date_added,
});
